import React, {useState, useRef} from 'react';
import { useHistory } from "react-router-dom";
import { useCookies } from 'react-cookie';
import AuthProvider from '@user-provider/AuthProvider'

import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles';
import {Box, Button, Typography, Grid} from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import MemberService from '@user-service/MemberService'
import clsx from "clsx";
import UserIdTextField from "./UserIdTextField";
import PasswordTextField from "./PassTextField";


const queryString = require('query-string');
const Login = () => {
    
    const classes = useStyles();
    const [, setCookie] = useCookies([AuthProvider.userCookieName])
    const [withId] = useState(true)
    const [isAuthUser, setAuthUser] = useState(false)
    const [ableNext, setAbleNext] = useState(false)
    const [loginResult, setLoginResult] = useState('')
    
    const idRef = useRef()
    const passwordRef = useRef()
    const history = useHistory()

    const qs = queryString.parse(window.location.search)
    const serviceType = qs.serviceType ? qs.serviceType : 'web'
    
    setTimeout(() => {
        if(window.APPBRHW){
            window.APPBRHW.IFCloseLoading()
        }
    }, 1300)
    
    
    const loginHandler = async () => {
        const result = await MemberService.login({
            loginID: idRef.current.value,
            password: passwordRef.current.value,
            deviceID: null
        })

        if(!result.success){
            setLoginResult(result.message) 
            return
        }

        //serviceType 설정
        result.data.serviceType = serviceType
        const cookie = await AuthProvider.setCookieWithProfile(result.data)
        setCookie(AuthProvider.userCookieName, cookie , AuthProvider.getCookieOption())

        if(window.APPBRHW){
          // BridgeProvider.instance.setToken(cookie.authToken).then()
          try {
            window.APPBRHW.IFSetToken(cookie.authToken)
            window.APPBRHW.IFSetServiceType(cookie.serviceType)    
          } catch {}
          //
          try {
            window.APPBRHW.setToken(cookie.authToken)
            window.APPBRHW.setServiceType(cookie.serviceType)    
          } catch {}
        }

        if('profile-selector' === result.data.memberType) {
          history.replace('/profile-selector')
          return
        }

        if(AuthProvider.isAppMode()){
          history.replace('/profile')
          return 
        }

        if('app:admin' === AuthProvider.getServiceType()){
          if('admin' !== result.data.memberType){
            setLoginResult('관리자 사용자만 로그인이 가능합니다.')
            return
          }
          history.replace('/manage/customer')
          return
        } 
        
        //페이지 이동 처리 url이 있을 경우            
        if(qs.rtu){
          document.location.replace(qs.rtu)       
        } else {
          history.replace(result.data.needFirstProfile ? '/profile' : '/dashboard')            
        }
    }
    
    const checkUserId = async () => {
        const isAuthedUser = await MemberService.checkUserId(idRef.current.value)
        if(isAuthedUser){
            setAuthUser(true)
        } else {
            if('web' !== serviceType){
                setLoginResult('등록된 아이디가 없습니다')
            } else {
                history.push('/join',{
                    loginID: idRef.current.value,
                })    
            }
            
        }
    }
    
    return (
        <React.Fragment>
            <Box className={classes.background} />
            <Box className={classes.logo}>
                <a href={"https://brhw.kr"}>
                    <img src={"https://brhw.kr/images/logo.png"} alt={'브레인헬스 로고'}/>
                </a>
                {'app:admin' === serviceType &&
                <Typography className={classes.admin}>관리자</Typography>
                }
            </Box>
            <Box className={classes.loginBoxWrap}>
                <Box className={classes.loginBox}>
                    {'app:admin' === serviceType &&
                    <Typography className={classes.title}>
                        <span style={{fontWeight: 600}}>관리자</span>  로그인
                    </Typography>
                    }

                    {'app:admin' !== serviceType &&
                    <Typography className={classes.title}>
                        <span style={{fontWeight: 600}}>로그인</span> 및 <span style={{fontWeight: 600}}>회원가입</span> <br/>시작하기
                    </Typography>
                    }

                    {'app:admin' !== serviceType &&
                        <Grid container direction={'column'} spacing={1}>
                            {/*<Grid item xs={12}>*/}
                            {/*    <Button className={clsx(classes.registerButton, classes.kakaoTalk)} onClick={() => {*/}
                            {/*        alert('준비중입니다.')*/}
                            {/*    }}>*/}
                            {/*        <Box className={classes.buttonIcon}>*/}
                            {/*            <img src={require('@assets/images/kakaoIcon.png')} alt={'카카오톡'}/>*/}
                            {/*        </Box>*/}
                            {/*        카카오로 간편시작*/}
                            {/*    </Button>*/}
                            {/*</Grid>*/}
                            <Grid item xs={12}>
                                <Button className={clsx(classes.registerButton, classes.phone)} onClick={() => {
                                    // setWithId(!withId)
                                }}>
                                    <Box className={classes.buttonIcon}>
                                        <img src={require('@assets/images/idIcon.png')} alt={'아이디'}/>
                                    </Box>
                                    <b>아이디</b>로 시작하기
                                    <Box className={classes.buttonIconRight}>
                                        {withId &&
                                        <img src={require('@assets/images/upIcon.png')} alt={'접기'}/>
                                        }
                                        {!withId &&
                                        <img src={require('@assets/images/downIcon.png')} alt={'열기'}/>
                                        }
                                    </Box>
                                </Button>
                            </Grid>
                        </Grid>
                    }
                    {/* 로그인 입력  */ }
                    <Collapse in={withId}>
                        <Box style={{marginTop: 40, marginBottom: 100}}>
                            <Grid container direction={'column'} spacing={4}>
                                <Grid item xs={12}>
                                    <UserIdTextField 
                                        ref={idRef}
                                        placeHolder={'아이디를 입력하세요'}
                                        next={async () => {
                                            await checkUserId()
                                        }}
                                        update={(isValidate) => {
                                            if('' !== loginResult){
                                                setLoginResult('')
                                            }
                                            
                                            if(isAuthUser){
                                                setAuthUser(false)
                                                passwordRef.current.value = ''
                                            }
                                            
                                            if(isValidate && false === ableNext){
                                                setAbleNext(true)
                                            } else if(false === isValidate && ableNext){
                                                setAbleNext(false)
                                            }
                                        }}/>    
                                </Grid>
                                <Grid item xs={12}>
                                    <Collapse in={isAuthUser}>
                                        <PasswordTextField 
                                            ref={passwordRef}
                                            focus={isAuthUser}
                                            placeholder={'비밀번호를 입력하세요'}
                                            valid={(isValid, value) => {
                                                setAbleNext((3 < value.length && isValid))      
                                            }}
                                            next={async () => {
                                                await loginHandler()
                                            }}
                                        />
                                    </Collapse>
                                    {'' !== loginResult &&
                                        <Box color={'red'} mt={1} textAlign={'center'}>{loginResult}</Box>
                                    }
                                </Grid>
                            </Grid>
                            <Grid container style={{marginTop: 60}}>
                               <Grid item xs={12}>
                                   <Button 
                                       disabled={!ableNext}
                                       onClick={!isAuthUser ? checkUserId.bind(this) : loginHandler.bind(this) }
                                       className={clsx(classes.registerButton, {
                                           [classes.nextAble]: ableNext,
                                           [classes.next]: !ableNext
                                       })}>{isAuthUser ? '로그인' : '다음'}</Button>

                                   <Box className={classes.findIdPassword} 
                                        onClick={() => {
                                            // history.push('/login-help')
                                            history.push('/reset-password')
                                        }}> 
                                       비밀번호가 기억나지 않아요 
                                   </Box>
                               </Grid>
                            </Grid>
                        </Box>
                    </Collapse>
                    <Box className={classes.bottom}>
                        브레인헬스 통합회원으로 진행됩니다
                    </Box>
                </Box>
            </Box>
        </React.Fragment>
    );
}



const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        justify: "center",
        alignItems: "center"
    },
    admin: {
        display: 'inline-block',
        color: 'white',
        fontWeight: 'bold',
        lineHeight: '56px',
        fontSize: '1.6rem',
        paddingLeft: 20
    },
    logo: {
        position: 'fixed',
        left: 30 ,
        top: 22,
        zIndex: 0,
        width: `100%`,
    },
    title: {
        fontSize: 40,
        fontFamily: 'Noto Sans KR',
        fontWeight: 300,
        marginBottom: 75,
        color: '#333',
        letterSpacing: -2
    },
    requestButton: {
        backgroundColor: '#8799AD',
        color: '#fff',
        height: 35,
        borderRadius: 10,
        marginTop: 10,
        paddingLeft: 10
    },
    buttonIcon: {
        position: 'absolute',
        left: 20,
        top: 'center',
        zIndex: 1,
        width: 32,
        height: 32
    },
    buttonIconRight: {
        position: 'absolute',
        right: 20,
        top: 'center',
        zIndex: 1,
        width: 32,
        height: 32
    },
    registerButton: {
        position: 'relative',
        width: '100%',
        height: 60,
        borderRadius: 10,
        color: '#333',
        fontSize: 19,
    },
    kakaoTalk: {
        background: '#FFEB03',
        color: '#3E313A',
        border: `1px solid #FFEB0`,
        '&:hover': {
            backgroundColor: '#CBBB09',
        }
    },
    phone: {
        background: '##fff',
        color: '#333',
        border: `1px solid #0052C1`
    },
    next: {
        backgroundColor: '#ddd',
        color: '#999'
    },
    nextAble: {
        backgroundColor: '#0053BF',
        color: 'white',
        '&:hover': {
            backgroundColor: '#04308E',
        }
    },
    formBox: {
        marginBottom: 60
    },
    background: {
        position: 'fixed',
        left: 0 ,
        top: 0,
        zIndex: 0,
        width: `100%`,
        height: '100vh',
        backgroundImage: `url(${require(`@assets/images/simplebg01.jpg`)})`,
        backgroundSize: 'cover'
    },
    loginBoxWrap: {
        top: 110,
        left: `calc(50% - 250px)`,
        // transform: `translate(-50%, -50%)`,
        position: 'absolute',
        width: 500,
        zIndex: 2,
    },
    loginBox: {
        width: '100%',
        minHeight: 670,
        borderRadius: 10,
        backgroundColor: '#fff',
        padding: 50,
        paddingTop: 75,
        marginBottom: 110
    },
    bottom: {
        position: "absolute",
        bottom: 140,
        width: 400,
        color: '#333',
        fontSize: 14,
        textAlign: 'center'
    },
    textField: {
        backgroundColor: '#ddd'
    },
    findIdPassword: {
        textDecoration: 'underline',
        width: '100%',
        textAlign: 'center',
        marginTop: 20,
        color: '#999',
        cursor: 'pointer'
    }
}));

//for redux 
Login.propType = {
    text: PropTypes.string.isRequired
}

export default Login
