import React from 'react';
import {Box, Button, Grid, Typography} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import TestService from "../../../services/TestService";
import clsx from "clsx";
import CheckIcon from '@material-ui/icons/Check';
import ChoiceLevel from "./ChoiceLevel";
import ChallengeLevel from "./ChallengeLevel";

export default class LevelDialog extends React.Component {
    constructor(props) {
      super();
      
      const measureType =  props.measureType ? props.measureType : 'measure'
      this.state = {
          open: false,
          choiceOpen: false,
          challengeOpen: false,
          onStart: props.onStart,
          history: props.history,
          measureType: measureType, // 'measure - 검사', 'develop' - 계발
          exerciseName: props.exerciseName,
          delay: 1000,
          limitTime: 60 * 30,
          showType: '1',
          view: '',
          pageView: 'level', // 'level' | 'time'
          currentLevel: '1',
          answerCount: 9,
          activeIcon:[0,1,2,3,4,5,6,7,8],
          backgroundColor: TestService.getColorName(measureType, props.exerciseID),
          zoom: window.innerHeight / 1080,
          programView: props.programView,
          exerciseID:props.exerciseID,
          programID: props.programID
        }
    }

    open(showType = '1', view= 'view1', description = '') {

        let activeIcon = []
        switch(view){
          case 'view1':
            activeIcon = [0,1,2,3,5,6,7]
            break
          case 'view9':
            activeIcon = [0,1,2,3,4,6,7]
            break
          default:
            activeIcon = [0,1,2,3,4,5,6,7]
        }

        this.setState({
            ...this.state,
            open: true,
            showType,
            view,
            description,
            activeIcon
        })
    }

    close() {
        this.setState({
            ...this.state,
            open: false,
            choiceOpen: false,
            challengeOpen: false,
        })
    }

    choiceLevel(){
      if('language' === this.state.view){
        this.start('level1')
      } else {
        this.setState({
          ...this.state,
          choiceOpen: true
        })  
      }
    }

    challengeLevel(){
      if('language' === this.state.view){
        this.start('level1')
      } else {
        this.setState({
          ...this.state,
          challengeOpen: true,
        })
      }
    }

  /**
   * 
   * @param stageIndex
   * @param delay
   * @param mode challenge , train
   */
    start(stageIndex, delay, mode, challengeIndex) {
      if(this.state.onStart){
          this.state.onStart(
            stageIndex,
            delay,
            this.state.limitTime,
            this.state.view,
            mode,
            challengeIndex
          )     
        }
        this.close()
        window.removeEventListener('resize', this.resizeHandler)
    }

    setDelay(delay) {
        this.setState({
            ...this.state,
            delay
        })
    }
    setLimitTime(limitTime) {
        this.setState({
            ...this.state,
            limitTime
        })
    }

    setView(view) {
        this.setState({
            ...this.state,
            view
        })
    }

    onExit(){
        if(this.state.history){
            this.state.history.replace('/measureTrain')
        }
    }

    resizeHandler(){
        this.setState({
            ...this.state,
            zoom: window.innerHeight / 1080
        })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeHandler.bind(this))
    }

    componentDidMount() {
        window.addEventListener('resize', this.resizeHandler.bind(this))
    }

    
    render() {
        if('' === this.state.view) return <></>
      
        const icons = ['인지','주의','집중','기억','수리','공감','언어','청각']
        const {activeIcon} = this.state
      
        let testName = this.state.exerciseName
      
        const buttonColSize = [4,4,4]
        if('language' === this.state.view || 'memoryComplex6' === this.state.programID){
          buttonColSize[0] = 6
          buttonColSize[1] = 6
        }
        return (
          <>
            <Dialog
                disableBackdropClick
                // fullScreen={fullScreen}
                open={this.state.open}
                fullWidth
                fullScreen
                className={clsx([ this.state.measureType, this.state.backgroundColor])}
                style={{
                  zoom: this.state.zoom,
                  zIndex: 998
                }}
            >
                <DialogContent style={{
                    backgroundImage: `url(${require('@assets/exercise/memoryComplex/frontBackground.jpg')})`,
                    backgroundSize: 'fit' ,
                    display: "flex",
                    justifyContent: "center",
                }}>
                    <Box id={'dialogStage'}
                         style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        height: '100%',
                        maxWidth: 1280, 
                    }}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <Box style={{marginBottom: 100}}>
                                    <Typography style={{
                                        fontSize: '3rem',
                                        fontWeight: 'bold',
                                    }}>{testName} 프로그램 </Typography>
                                    <Box mt={2} mb={5} 
                                       style={{fontSize: '1.7rem', fontWeight: 'bold'}} 
                                       dangerouslySetInnerHTML={{ __html: this.state.description}}>
                                    </Box>
                                </Box>
                                <Box mt={5} mb={5}>
                                    <Grid container spacing={1} >
                                        {icons.map((text,index) => {
                                            const active = activeIcon.includes(index)
                                            const activeColor = active ? '#084088' : '#fff'
                                            return (
                                                <Grid item key={index} >
                                                    <Box style={{
                                                        border: `4px solid ${activeColor}`,
                                                        borderRadius: 30,
                                                        fontWeight: 'bold',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        flexWrap: 'wrap',
                                                        padding: '5px 24px'
                                                    }}>
                                                        <CheckIcon style={{color: activeColor, fontSize: '2rem', fontWeight: 'bold', paddingRight: 5}}/>
                                                        <Typography style={{fontSize: '1.5rem'}} variant={'subtitle2'}>{text}</Typography>
                                                    </Box>
                                                </Grid>
                                            )
                                        })}

                                    </Grid>
                                </Box>

                                <Box mt={5} mb={5}>
                                    <Grid container spacing={1} >
                                        <Grid item xs={buttonColSize[0]}>
                                            <Button fullWidth variant={"outlined"}
                                                    size={'large'}
                                                    style={{backgroundColor: '#ddd', fontSize: '1.4rem', borderRadius: 10}}
                                                    onClick={this.onExit.bind(this)}>
                                                종료
                                            </Button>
                                        </Grid>
                                      {(
                                          'language' !== this.state.view &&
                                          'memoryComplex6' !== this.state.programID
                                        )
                                        &&
                                        <>
                                        <Grid item xs={buttonColSize[1]}>
                                            <Button fullWidth variant={"contained"}
                                                    size={'large'}
                                                    color={'primary'}
                                                    style={{backgroundColor: '#008AD2', fontSize: '1.4rem' , borderRadius: 10}}
                                                    onClick={this.choiceLevel.bind(this)}>
                                                훈련
                                            </Button>
                                        </Grid>
                                       
                                        <Grid item xs={buttonColSize[2]}>
                                          <Button fullWidth variant={"contained"}
                                                  size={'large'}
                                                  color={'primary'}
                                                  style={{backgroundColor: '#ed8523', fontSize: '1.4rem' , borderRadius: 10}}
                                                  onClick={this.challengeLevel.bind(this)}>
                                            두뇌계발
                                          </Button>
                                        </Grid>
                                        </>
                                        }
                                        
                                        {(
                                            'language' === this.state.view ||
                                            'memoryComplex6' === this.state.programID
                                          )  
                                          &&
                                          <Grid item xs={buttonColSize[1]}>
                                            <Button fullWidth variant={"contained"}
                                                    size={'large'}
                                                    color={'primary'}
                                                    style={{backgroundColor: '#008AD2', fontSize: '1.4rem' , borderRadius: 10}}
                                                    >
                                              준비 중입니다.
                                            </Button>
                                          </Grid>
                                        }
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    height: '100%',
                                    paddingLeft: 20,
                                }}>
                                    <img src={require(`@assets/exercise/memoryComplex/${this.state.view}_front.png`)} style={{width: '80%'}}/>
                                </Box>
                            </Grid>

                        </Grid>
                    </Box>
                    
                </DialogContent>
            </Dialog>
            
            {'language' !== this.state.view &&
            <>
              <ChoiceLevel 
                view={this.state.view}
                open={this.state.choiceOpen}
                start={(stageIndex, delay) => {
                  this.start(stageIndex, delay, 'train')
                }}
                programView={this.state.programView}
                close={() => {
                  this.setState({
                    ...this.state,
                    choiceOpen: false
                  })
                }}
              />
              <ChallengeLevel
                view={this.state.view}
                open={this.state.challengeOpen}
                start={(stageIndex, delay, challengeLevel) => {
                  this.start(stageIndex, delay, 'challenge', challengeLevel)
                }}
                exerciseID={this.state.exerciseID}
                programView={this.state.programView}
                close={() => {
                  this.setState({
                    ...this.state,
                    challengeOpen: false
                  })
                }}
              />
            </>
            }
          </>
        );
    }
}



