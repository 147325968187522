import React, {useEffect} from 'react';
import Axios from 'axios';
import {
    BrowserRouter,
    Switch,
    Route,
} from 'react-router-dom';
import ReactGA from 'react-ga';
import '@user-style/chart.css';
import '@user-style/style.css';
import '@user-style/default.css';
import '@user-style/pages.css';
import '@user-style/style.mobile.css';
import "@fontsource/noto-sans-kr";

import { Layout as UserLayout } from './user/components/Layout';
import AuthProvider from '@user-provider/AuthProvider'
import Moment from 'moment-timezone'
import CommonHelper from "./user/helper/CommonHelper";
import {useCookies} from "react-cookie";
import BridgeProvider from "./user/provider/BridgeProvider";


require('dotenv').config();
export function App() {
    const [cookies] = useCookies()
    AuthProvider.setCookie(cookies._sass)
    
    ReactGA.initialize('G-3QV9LH1387');
    ReactGA.pageview(window.location.pathname + window.location.search);
    
    BridgeProvider.init().then()
    
    Axios.interceptors.request.use(function (config) {
        try {
            const token = AuthProvider.getToken()
            const profileID = AuthProvider.getProfileID()
            
            if(token){
                config.headers.get['Pragma'] = 'no-cache';
                config.headers.get['Cache-Control'] = 'no-cache, no-store';
                config.headers.Authorization = 'Bearer ' + token;    
                config.headers.Timezone = Moment.tz.guess()
                config.headers.profileID = profileID
            }
        }catch(e){}
        return config;
    });

    useEffect(() => {
        console.warn = () => {};
    }, [])

    useEffect(() => {
        CommonHelper.wakeLock()
        return () => {
            CommonHelper.releaseWakeLock()
        }
    },[])
    
    

    
    return (
        <React.Fragment>

            <div id={'wrap'} 
                 onContextMenu={(e)=> e.preventDefault()}
                 onDragStart={() => {}}
                 >
                    <BrowserRouter>
                        <Switch>
                            <Route path="/" component={UserLayout} />
                        </Switch>
                    </BrowserRouter>
            </div>
        </React.Fragment>
    )

}


